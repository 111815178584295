<template>
	<div class="code-container">
		<Excel></Excel>
	</div>
</template>

<script>
import Excel from '@/components/excel/netExcel.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    Excel
  }
}
</script>

<style scoped lang="less">
	.code-container{
		padding: 20px;
		// height: calc(100vh - 100px);
		height: 877px;
		overflow-y: auto;
		box-sizing: border-box;
		// background-color: #e7e7e7;
		// background-color: #000000;
	}
</style>
